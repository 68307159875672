import { useState, useEffect } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { 
  DropdownMenu, 
  DropdownMenuContent, 
  DropdownMenuItem, 
  DropdownMenuTrigger 
} from "@/components/shadcn-ui/dropdown-menu";
import { useSelector } from 'react-redux';
import { upperCase } from '@/utils/constants';
import { selectReportsTotalCount } from '@/redux/ReportsSlice';

const StatusDropdown = ({ tabs, selected, handler, reports, disable = false, showReportCount = false, isDrawer }) => {
  const selectedText = upperCase(selected);
  const [selectedStatus, setSelectedStatus] = useState(selectedText);
  
  const reportsCount = useSelector(selectReportsTotalCount)

  const translateStatus = async (status) => {
    const newLanguage = await window.Weglot.getCurrentLang();
    const words = [{ t: 1, w: status }];
    const languageTo = newLanguage;
    const data = await window.Weglot.translate({ words, languageTo });

    if (data) {
      setSelectedStatus(data[0]);
    } else {
      setSelectedStatus(status);
    }
  };

  useEffect(() => {
    if (window.Weglot) {
      translateStatus(selectedText);
    } else {
      setSelectedStatus(selectedText);
    }

    const handleLanguageChange = () => {
      translateStatus(selectedText);
    };

    window.Weglot.on('languageChanged', handleLanguageChange);

    return () => {
      window.Weglot.off('languageChanged', handleLanguageChange);
    };
  }, [selectedText]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger 
        disabled={disable} 
        className={`border-[1px] h-10 border-[#E2E4E9] inline-flex items-center justify-center gap-x-1.5 rounded-[10px] bg-white px-4 py-2 text-sm font-normal text-gray-900 hover:border-[#CED0D5]`}
      >
        <span 
          className={`flex ${isDrawer ? "text-black font-medium text-[14px]" : "text-[#525866]"} h-full items-center justify-center whitespace-nowrap overflow-hidden text-ellipsis`}
        >
          {selectedStatus}
        </span>
        <ChevronDownIcon 
          className={`h-5 w-5 ${isDrawer ? "text-black" : "text-gray-400"}`} 
          aria-hidden="true" 
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent align='start' className='p-2'>
        {tabs.map((tab) => (
          <DropdownMenuItem 
            key={tab.value}
            onSelect={() => handler(reports ? tab.name : tab?.value, selected)}
            className="cursor-pointer py-2 text-[14px] text-gray-700 focus:text-gray-900"
          >
            <span className="inline-block">
              <span>{tab.name}</span>
              {showReportCount && ( 
                <> 
                  <span className="mx-1 wg-ignore">&middot;</span>
                  <span className="wg-ignore">{reportsCount[tab.value]}</span>
                </>
              )}
            </span>
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default StatusDropdown;
