import React, { useEffect, useRef, useState, useCallback } from 'react'
import RecordForm from './RecordForm';
import CheckCircle from '../images/check-circle.png'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { selectFileUploading, selectIntegration, selectRecordingDuration, selectRecordingStarted, selectReportId, setFileUploading, setIntegration, setRecordingDuration, setRecordingStarted, setSelectedReport } from '../redux/ReportsSlice'
import Button from './ui/Button'
import Microphone from '../images/microphone.png'
import { GradientBorder } from './ui/GradientBorder'
import Loader from './Loader'
import { createRecord, handleUpdateStatus, handleUpdateError, getSignedUrl, uploadToS3, getReport, checkIfOnline, createRecordingLog } from '../utils/HelperFunctions'
// import Recorder from './Recorder'
// import { FFmpeg } from '@ffmpeg/ffmpeg';
// import { toBlobURL } from '@ffmpeg/util';
import { Tooltip } from 'react-tooltip';
import { useCurrentTool } from '@/utils/ToolHelpers';
import succeedIcon from '../images/SVG/succeedIcon.svg'
import { handleLogout } from '@/utils/authSession';
import AudioRecorder from './AudioRecorder';

function RecordAudio({ isDisabled, customTemplate, languages, allModelsLanguageList, setHidingtabs, toolId }) {

  const { currentToolFields, generation_name } = useCurrentTool();

  const payload = useSelector((state) => state?.reportsSlice?.latestPayload)
  const uploading = useSelector(selectFileUploading)
  const [recordingStatus, setRecordingStatus] = useState('start')
  // eslint-disable-next-line no-unused-vars
  const [recordingTime, setRecordingTime] = useState(0)
  const [generateReport, setGenerateReport] = useState('')
  const [tempReport, setTempReport] = useState({})
  const [generating, setGenerating] = useState(false)
  const [, setPauseRecording] = useState(false)
  const [recordedBlob, setRecordedBlob] = useState([])
  const recordingStarted = useSelector(selectRecordingStarted)
  const reocordingDuration = useSelector(selectRecordingDuration);
  const newReportId = useSelector(selectReportId)
  const reportIdRef = useRef(null)
  const integration = useSelector(selectIntegration);
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [documentInfo, setDocumentInfo] = useState({
    documentId: '',
    rowNumber: 0,
    signedUrl: '',
    audioUploaded: false,
    statusUpdated: false,
  });

  const handleResetState = useCallback(() => {
    setRecordingStatus('start');
    setRecordingTime(0);
    setGenerateReport('');
    setTempReport({});
    setPauseRecording(false);
    setGenerating(false);
    setHidingtabs(false);
    dispatch(setFileUploading(false));
    reportIdRef.current = null;
  }, [dispatch, setHidingtabs]);

  // const [ffmpegLoaded, setFfmpegLoaded] = useState(false);
  // const ffmpegRef = useRef(new FFmpeg());

  // const loadFfmpeg = async () => {
  //   const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd'
  //   const ffmpeg = ffmpegRef.current;
  //   ffmpeg.on('log', ({ message }) => {
  //       console.log(message);
  //   });
  //   await ffmpeg.load({
  //       coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
  //       wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
  //   });
  //   setFfmpegLoaded(true);
  // }

  // const convertWebmToMp3 = async (webmBlob) => {
    
  //   if (!ffmpegLoaded) {
  //     await loadFfmpeg();
  //   }

  //   const ffmpeg = ffmpegRef.current;
  //   await ffmpeg.writeFile('input.webm', await fetchFile(webmBlob));
  //   await ffmpeg.exec(['-i', 'input.webm', 'output.mp3']);
  //   const data = await ffmpeg.readFile('output.mp3');

  //   return new Blob([data.buffer], {type: 'audio/mp3'});
  //   }


  // const formatTime = (seconds) => {
  //   const hours = Math.floor(seconds / 3600);
  //   const minutes = Math.floor((seconds % 3600) / 60);    const remainingSeconds = seconds % 60;
  //   return `${hours}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  // };

  useEffect(() => {
    if (payload?.id === reportIdRef.current) {
      if (payload.report_fetched) {
        setTempReport(payload);
      } else if (payload.status === "completed") {
        handleResetState();
      }
    }
    if (tempReport?.report?.length > 0 && generateReport === 'generating') {
      setGenerating(false)
      setGenerateReport('audio-report-generated');
      dispatch(setSelectedReport(tempReport));
    }
  }, [payload, handleResetState, tempReport, dispatch, generateReport]);

  const retrySubmit = () => {
    setGenerateReport('');
    setRecordingStatus('stop');
    setGenerating(false);
    dispatch(setFileUploading(false));
    toast.error('Network error. Please check your internet connection and try again.');
  }

  const clearGenerateStates = () => {
    setDocumentInfo({
      documentId: '',
      rowNumber: 0,
      signedUrl: '',
      audioUploaded: false,
      statusUpdated: false,
    });
  };

  
  const handleGenerateReport = async ({ toolFieldsData, selectedFile, recordedAudioBlob, reportLanguage, audioLanguage, comment, template, uses_custom_template, urgent }) => {
    try {
        if (recordingStarted && newReportId) {
          createRecordingLog({ type:'generate', duration: reocordingDuration, reportId:newReportId, createdAt: new Date().toISOString() });
          dispatch(setRecordingStarted(false))
          dispatch(setRecordingDuration(0))
        }

        const userId = localStorage.getItem('user_id')

        const allowedAudioTypes = [
            'audio/flac',
            'audio/mp4',
            'audio/mpeg',
            'audio/ogg',
            'audio/wav',
            'audio/webm'
        ];

        if (selectedFile) {
            if (selectedFile?.size / (1024 * 1024) > 90.0) {
                toast.error("The audio file is too large. The maximum size is 90 MB.");
                handleResetState();
                return;
            }
            if (!allowedAudioTypes?.includes(selectedFile?.type)) {
                toast.error("Please upload an audio file.");
                handleResetState();
                return;
            }
        }
        else {
            if (recordedAudioBlob?.size / (1024 * 1024) > 90.0) {
                toast.error("The audio file is too large. The maximum size is 90 MB.");
                return;
            }
        }
        dispatch(setFileUploading(true));
        let mergedBlob;
        if(recordedBlob){
          mergedBlob = recordedBlob;
        }

        // if (mergedBlob?.type === 'audio/webm'){
        //   console.log('Converting webm to mp3')
        //   mergedBlob = await convertWebmToMp3(mergedBlob)
        // }
        // 
        toolFieldsData.forEach((field, index) => {
          if(index > 0)
            localStorage.setItem(field.name, field.value)
        })
        localStorage.setItem('audio_language', audioLanguage)
        localStorage.setItem('report_language', reportLanguage)
        localStorage.setItem('uses_custom_template',uses_custom_template)

        let { documentId, rowNumber, signedUrl, audioUploaded, statusUpdated } = documentInfo;

        if (!documentId) {
          const document = await createRecord({ reportId:newReportId, userId, toolFieldsData, reportLanguage, uses_custom_template, audioLanguage, comment, template, meta_type: mergedBlob?.type, toolId, urgent, integration });
          documentId = document?.documentId;
          rowNumber = document?.row_number;
          if (!documentId) {
            retrySubmit();
            return;
          }
          setDocumentInfo((prevState) => ({ ...prevState, documentId, rowNumber }));
        }

        if (!signedUrl) {
          signedUrl = await getSignedUrl({ id: documentId, name: documentId, type: "upload" });
          if (!signedUrl) {
            retrySubmit();
            return;
          }
          setDocumentInfo((prevState) => ({ ...prevState, signedUrl }));
        }

        if (!audioUploaded) {
          const [uploadStatus, isOnline] = await Promise.all([
            uploadToS3({ id: documentId, signedUrl, file: mergedBlob }),
            checkIfOnline()
          ]);
        
          if (!isOnline || !uploadStatus) {
            retrySubmit();
            return;
          }
          setDocumentInfo((prevState) => ({ ...prevState, audioUploaded: true }));
        }

        dispatch(setFileUploading(false));
        setGenerating(true);
        dispatch(setIntegration('reset'))

        if (!statusUpdated) {
          const recordUpdateStatus = await handleUpdateStatus({ status: 'processing', documentId });
          if (!recordUpdateStatus) {
            retrySubmit();
            return;
          }
          setDocumentInfo((prevState) => ({ ...prevState, statusUpdated: true }));
        }

        const { response, responseData } = await getReport(documentId, currentToolFields);

        if (responseData.statusCode !== 200) {
            if (response.status === 401) {
                handleUpdateError({ id: documentId, error: "Session expired." })
                handleLogout()
                toast.error('Your session has expired. Please login and try again.')
                return
            } else {
                clearGenerateStates()
                handleResetState()
                handleUpdateError({ id: documentId, error: JSON.stringify(responseData) })
                return
            }
        }
        reportIdRef.current = documentId
        clearGenerateStates();
    } catch (error) {
        console.log("API error ", error);
        if (JSON.stringify(error) === '{}') {
            handleUpdateError({ id: documentInfo.documentId, error: 'Failed to make request to backend server' })
        }
        else {
            handleUpdateError({ id: documentInfo.documentId, error: JSON.stringify(error) })
        }
        clearGenerateStates()
        handleResetState()
    }
  }

  const genertae=()=>{
    setRecordingStatus("start");
  }

  const handleRecord = async (e) => {
    e.preventDefault();

    try {
      const permission = await navigator.mediaDevices.getUserMedia({ audio: true });
      if (permission) {
        setRecordingStatus('recording');
      } else {
        toast.error('Microphone permission denied.');
        handleResetState()
      }
    } catch (error) {
      console.error('Error starting recording:', error);
    }
  };

  const handleGoToReport = () => {
    navigate(`/scribe/${documentInfo.rowNumber}`)
  }

  const handleAudio = (blob) => {
    setRecordedBlob(blob)
    setRecordingStatus('stop')
  }

  const handlePauseRecording = (isRecordingPaused) => {
    setPauseRecording(isRecordingPaused)
  }

  const disabled = isDisabled.loading || isDisabled.noWords;

  return (
    <>
      {
        recordingStatus === 'start' && (
          <>
            {isDisabled.noWords && <Tooltip id="no-words" place="bottom"/>}
            <div className='flex flex-col justify-center items-center text-center w-full p-5 rounded-xl border border-[#E4E4E7]' >
              <GradientBorder>
                <div className="shadow-sm bg-white rounded-full px-4 py-4">
                  <img width={'24px'} src={Microphone} alt='recording mic ' />
                </div>
              </GradientBorder>
              <h1 className='font-medium mt-[16px] md:text-[24px] text-xl'>Record your voice</h1>
              <p className='mt-[4px] text-[#505050] text-[16px] font-SuisseIntlLight font-normal'>Capture your speech to be processed through our system</p>
              <div className='mt-[16px]' onClick={!disabled ? handleRecord : undefined} data-tooltip-id='no-words' data-tooltip-content='No more words left. Please contact MPAssist.'>
                <Button className={`${disabled && 'cursor-not-allowed opacity-80'}`}>
                  Start recording
                </Button>
              </div>
            </div>
          </>
        )
      }
      {
        recordingStatus === 'recording' && (
          <>
            <div className='flex w-full flex-col justify-center items-center'>
              <div className='flex w-full flex-col justify-center items-center p-5 rounded-xl border border-[#E4E4E7]'>
                <AudioRecorder onAudioPause={handlePauseRecording} onAudioStop={handleAudio} />
                {/* <Recorder handleAudio={handleAudio} autoStart={true} handlePauseRecording={handlePauseRecording} setRecordingTime={setRecordingTime} setHidingtabs={setHidingtabs}/> */}
              </div>
            </div>
          </>
        )
      }
      {
        recordingStatus === 'stop' && generateReport ===''&&  (
          <div className='flex flex-col justify-center items-center w-full'>
            <GradientBorder >
              <div className="shadow-sm bg-white rounded-full p-3">
                <img className='max-w-none w-4 h-4' src={succeedIcon} alt='recording mic ' />
              </div>
            </GradientBorder>
            <h1 className='font-bold mt-[16px] font-SuisseIntlLight md:text-[24px] text-xl'>Your speech has been recorded</h1>
            <p className='sm:w-[320px] max-w-[320px] mt-[4px] mb-[12px] text-[#505050] text-[16px] font-SuisseIntlLight font-normal'>Fill the form below to generate your {generation_name.toLowerCase()}</p>
            <RecordForm GenerateReport={setGenerateReport} HandleResetState={handleResetState} UploadNewRecording={genertae} customTemplates={customTemplate} language={languages} allModelsLanguageList={allModelsLanguageList} handleGenerateReport={handleGenerateReport} recordedAudioBlob />
            
          </div>
        )
      }
      {
        generateReport === 'generating' && (
          <div className='flex flex-col h-[97dvh] md:h-fit justify-center items-center'>
            <GradientBorder>
              <div className="shadow-sm bg-white rounded-full px-4 py-4">
                <Loader />
              </div>
            </GradientBorder>
            {
              uploading && (
                <div className='flex flex-col items-center w-full'>
                  <>
                    <h1 className='font-medium mt-[16px] md:text-[24px] text-xl'>Uploading...</h1>
                    <p className='sm:w-[320px] max-w-[320px] text-[#505050] mt-[4px] font-SuisseIntlLight font-medium text-[16px]'>Please wait a few seconds</p>
                  </>
                  <Button variant='disabled' className='mt-[16px] max-w-fit cursor-not-allowed' onClick={()=>{}}>
                    Add New {generation_name}
                  </Button>
                </div>
              ) 
            }
            {
              generating && (
                <div className='flex flex-col items-center w-full'>
                  <>
                    <h1 className='font-medium mt-[16px] md:text-[24px] text-xl'>Generating...</h1>
                    <p className='sm:w-[320px] max-w-[320px] text-[#505050] mt-[4px] font-SuisseIntlLight font-normal text-[16px]'>Please wait a few minutes (it’s okay to leave this screen)</p>
                  </>
                  <Button variant='light' className='mt-[16px] max-w-fit' onClick={handleResetState}>
                    Add New {generation_name}
                  </Button>
                </div>
              )
            }   
          </div>
        )
      }
      {
        generateReport === 'audio-report-generated' && (
          <div className='flex flex-col h-[96dvh] md:h-fit justify-center items-center w-full'>
            <GradientBorder variant={2}>
              <div className="shadow-sm bg-white rounded-full px-4 py-4">
                <img width={'24px'} src={CheckCircle} alt='recording mic ' />
              </div>
            </GradientBorder>
            <h1 className='font-medium mt-[16px] md:text-[24px] text-xl'>The {generation_name.toLowerCase()} has been generated</h1>
            <p className='sm:w-[320px] max-w-[320px] mt-[4px] text-[16px] text-[#505050] font-SuisseIntlLight font-normal'>You can review it by clicking the button below</p>
            <div>
              <Button onClick={handleGoToReport} className=' mt-[16px] ' variant='light'>
              View {generation_name}
              </Button>
            </div>
          </div>
        )
      }
    </>
  )
}

export default RecordAudio
