import React, { useEffect, useRef, useState } from 'react';
import RecordRTC, { StereoAudioRecorder } from 'recordrtc';
import lamejs from 'lamejs';

// --- Helper function: MP3 encoding using lamejs ---
function encodeMP3(audioBuffer, bitRate = 64) {
  const numChannels = audioBuffer.numberOfChannels;
  const sampleRate = audioBuffer.sampleRate;
  const mp3Encoder = new lamejs.Mp3Encoder(numChannels, sampleRate, bitRate);
  const blockSize = 1152;
  let mp3Data = [];

  if (numChannels === 1) {
    const channelData = audioBuffer.getChannelData(0);
    var cd = new Float32Array(channelData.length);
    for(var i=0;i<channelData.length;i++) {
      cd[i] = channelData[i]*32767.5;
    }
    for (let i = 0; i < cd.length; i += blockSize) {
      const sampleChunk = cd.subarray(i, i + blockSize);
      const mp3buf = mp3Encoder.encodeBuffer(sampleChunk);
      if (mp3buf.length) {
        mp3Data.push(new Int8Array(mp3buf));
      }
    }
  } else if (numChannels === 2) {
    const left = audioBuffer.getChannelData(0);
    const right = audioBuffer.getChannelData(1);
    var l = new Float32Array(left.length);
    var r = new Float32Array(right.length);
    for(var j=0;j<left.length;j++) {
      l[j] = left[j]*32767.5;
      r[j] = right[j]*32767.5;
    }
    for (let j = 0; j < l.length; j += blockSize) {
      const leftChunk = l.subarray(j, j + blockSize);
      const rightChunk = r.subarray(j, j + blockSize);
      const mp3buf = mp3Encoder.encodeBuffer(leftChunk, rightChunk);
      if (mp3buf.length) {
        mp3Data.push(new Int8Array(mp3buf));
      }
    }
  }

  const mp3buf = mp3Encoder.flush();
  if (mp3buf.length) {
    mp3Data.push(new Int8Array(mp3buf));
  }
  return new Blob(mp3Data, { type: 'audio/mp3' });
}

// --- Recorder Widget Component ---
const RecorderWidget = ({ onAudioPause, onAudioStop }) => {
  const recorderRef = useRef(null);
  const mediaStreamRef = useRef(null);
  const audioPlayerRef = useRef(null);
  const segmentsRef = useRef([]); // Stores each recorded segment as a Blob

  // Use a persistent AudioContext for decoding.
  const audioContextRef = useRef(new (window.AudioContext || window.webkitAudioContext)());

  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [audioURL, setAudioURL] = useState('');

  // Determine MIME type: use 'audio/mp4' on iOS, 'audio/webm' otherwise.
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const mimeType = isIOS ? 'audio/mp4' : 'audio/webm';

  // Start a new recorder instance on the current media stream.
  const startNewRecorder = () => {
    recorderRef.current = new RecordRTC(mediaStreamRef.current, {
      recorderType: StereoAudioRecorder,
      mimeType,
      audioBitsPerSecond: 8000,
      desiredSampRate: 16000,
      numberOfAudioChannels: 1,
    });
    recorderRef.current.startRecording();
    setIsRecording(true);
    setIsPaused(false);
  };

  // Auto-start: request audio stream and begin recording on mount.
  useEffect(() => {
    const init = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        mediaStreamRef.current = stream;
        segmentsRef.current = [];
        startNewRecorder();
      } catch (err) {
        console.error('Error initializing media:', err);
      }
    };
    init();

    return () => {
      if (recorderRef.current) {
        recorderRef.current.stopRecording(() => {});
      }
      if (mediaStreamRef.current) {
        mediaStreamRef.current.getTracks().forEach((track) => track.stop());
      }
    };
  // eslint-disable-next-line
  }, [mimeType]);

  // Merge all segments: decode each Blob into an AudioBuffer,
  // then combine them into one continuous AudioBuffer.
  const mergeSegments = async () => {
    const audioContext = audioContextRef.current;
    const buffers = [];
    for (let i = 0; i < segmentsRef.current.length; i++) {
      const blob = segmentsRef.current[i];
      const arrayBuffer = await blob.arrayBuffer();
      try {
        const decoded = await audioContext.decodeAudioData(arrayBuffer);
        buffers.push(decoded);
      } catch (err) {
        console.error(`Decoding error in segment ${i}:`, err);
      }
    }
    if (buffers.length === 0) return null;
    const numChannels = buffers[0].numberOfChannels;
    const sampleRate = buffers[0].sampleRate;
    const totalLength = buffers.reduce((sum, buffer) => sum + buffer.length, 0);

    const outputBuffer = audioContext.createBuffer(numChannels, totalLength, sampleRate);
    let offset = 0;
    for (const buffer of buffers) {
      for (let channel = 0; channel < numChannels; channel++) {
        outputBuffer.getChannelData(channel).set(buffer.getChannelData(channel), offset);
      }
      offset += buffer.length;
    }
    return outputBuffer;
  };

  // Merge segments and encode the merged AudioBuffer to MP3.
  const mergeAndEncodeToMP3 = async () => {
    const mergedBuffer = await mergeSegments();
    if (!mergedBuffer) return null;
    return encodeMP3(mergedBuffer, 64); // 64 kbps bitrate
  };

  // On Pause: stop the current segment, save its Blob, and update playback preview.
  const pauseRecording = () => {
    if (recorderRef.current && isRecording && !isPaused) {
      recorderRef.current.stopRecording(() => {
        const segment = recorderRef.current.getBlob();
        segmentsRef.current.push(segment);
        setIsPaused(true);
        setIsRecording(false);
        onAudioPause(true);
        mergeAndEncodeToMP3().then((mp3Blob) => {
          if (mp3Blob) {
            setAudioURL(URL.createObjectURL(mp3Blob));
          }
        });
      });
    }
  };

  // On Resume: start a new recorder instance.
  const resumeRecording = () => {
    if (mediaStreamRef.current && isPaused) {
      startNewRecorder();
    }
  };

  // On Stop: finish current segment if recording, merge all segments, encode to MP3, and call callback.
  const stopRecording = () => {
    if (recorderRef.current && isRecording) {
      recorderRef.current.stopRecording(() => {
        const segment = recorderRef.current.getBlob();
        segmentsRef.current.push(segment);
        setIsRecording(false);
        setIsPaused(false);
        mergeAndEncodeToMP3().then((mp3Blob) => {
          if (mp3Blob) {
            const url = URL.createObjectURL(mp3Blob);
            setAudioURL(url);
            onAudioStop(mp3Blob);
          }
        });
      });
    } else if (isPaused) {
      mergeAndEncodeToMP3().then((mp3Blob) => {
        if (mp3Blob) {
          const url = URL.createObjectURL(mp3Blob);
          setAudioURL(url);
          onAudioStop(mp3Blob);
        }
      });
    }
  };

  // Restart: discard all segments and start a fresh recording session.
  const restartRecording = () => {
    segmentsRef.current = [];
    setAudioURL('');
    if (recorderRef.current && isRecording) {
      recorderRef.current.stopRecording(() => {
        setIsRecording(false);
        setIsPaused(false);
        startNewRecorder();
      });
    } else if (isPaused) {
      setIsPaused(false);
      startNewRecorder();
    }
  };

  // Playback controls.
  const playAudio = () => {
    if (audioPlayerRef.current) {
      audioPlayerRef.current.play();
    }
  };

  const pauseAudio = () => {
    if (audioPlayerRef.current) {
      audioPlayerRef.current.pause();
      onAudioPause(true);
    }
  };

  // --- UI Styling for a modern minimal widget ---
  const containerStyle = {
    maxWidth: '400px',
    margin: '20px auto',
    padding: '15px',
    borderRadius: '8px',
    boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
    background: '#fff',
    fontFamily: 'Arial, sans-serif',
    textAlign: 'center',
  };

  const buttonStyle = {
    padding: '8px 12px',
    margin: '5px',
    borderRadius: '4px',
    border: 'none',
    background: '#007bff',
    color: '#fff',
    cursor: 'pointer',
    fontSize: '14px',
  };

  let statusText = 'Idle';
  if (isRecording) {
    statusText = 'Recording...';
  } else if (isPaused) {
    statusText = 'Paused';
  } else if (audioURL) {
    statusText = 'Stopped';
  }

  return (
    <div style={containerStyle}>
      <h3 style={{ margin: '0 0 10px 0' }}>Audio Recorder</h3>
      <p>{statusText}</p>
      {/* Recording Controls */}
      <div>
        {isRecording && !isPaused ? (
          <>
            <button style={buttonStyle} onClick={pauseRecording}>
              Pause
            </button>
            <button style={buttonStyle} onClick={stopRecording}>
              Stop
            </button>
          </>
        ) : isPaused ? (
          <>
            <button style={buttonStyle} onClick={resumeRecording}>
              Resume
            </button>
            <button style={buttonStyle} onClick={stopRecording}>
              Stop
            </button>
            <button style={buttonStyle} onClick={playAudio}>
              Play
            </button>
          </>
        ) : (
          <>
            <button style={buttonStyle} onClick={restartRecording}>
              Restart
            </button>
            {audioURL && (
              <>
                <button style={buttonStyle} onClick={playAudio}>
                  Play
                </button>
                <button style={buttonStyle} onClick={pauseAudio}>
                  Pause
                </button>
              </>
            )}
          </>
        )}
      </div>
      {/* Playback UI */}
      {audioURL && (
        <div style={{ marginTop: '15px' }}>
          <audio ref={audioPlayerRef} src={audioURL} controls style={{ width: '100%' }} />
        </div>
      )}
    </div>
  );
};

export default RecorderWidget;
